import * as Styled from './mapStyles';
import GoogleMapReact from 'google-map-react';
import React from 'react';
import IconPinPayment from '../../images/pin.png';

const GoogleMap =({sectionData})=>{

    const lat = parseFloat(19.3019313);
    const lng = parseFloat(-81.3776488);
    const markerStyle = {
        position: 'absolute',
        top: '100%',
        left: '50%',
        height: '70px',
        transform: 'translate(-50%, -100%)',
    };
    
    const handleApiLoaded = (map, maps) => {    
        const styles = [
            {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [{ color: '#B1E5F9' }],
            },
        ];
        
        // eslint-disable-next-line no-undef
        const styledMap = new google.maps.StyledMapType(styles, {
            name: 'Styled Map',
            });
            map.mapTypes.set('map_style', styledMap);
            map.setMapTypeId('map_style');
        }

    return <Styled.MainDiv>
        <Styled.GoogleMapContainer>
        <GoogleMapReact
                bootstrapURLKeys={{
                key: process.env.GATSBY_GOOGLE_MAP_APIKEY,
                libraries: ['places', 'geometry'],
                }}
                center={{ lat, lng }}
                zoom={18}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                handleApiLoaded(map, maps)
                }
            >
                <div
                lat='19.3019213'
                lng='-81.3774088'
                >
                <img style={markerStyle}  alt='s' src={IconPinPayment} role="presentation" />
                </div>
            </GoogleMapReact>
        </Styled.GoogleMapContainer>
    </Styled.MainDiv>
}

export default GoogleMap;