import styled from 'styled-components';

export const MainDiv = styled.div`
margin-top: 5%;
margin-bottom: 5%;
`;

export const GoogleMapContainer = styled.div`
    height: 780px;
    display: flow-root;
    overflow: hidden;
    border-radius: 15px;

    @media (max-width: 1440px) {
        height:700px;
    }

    @media (max-width: 1024px) {
        height:720px;
    }

    @media (max-width: 768px) {
        height:300px;
    }
    

    & .gm-style-iw-c{
        left:0 !important;
    }
`;